import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Page } from '@/interfaces/api/Page';
import Filter from '@/interfaces/api/Filter';
import { Pagination } from '@/interfaces/api/Pagination';
import { Tag } from '@/interfaces/models/Tag';

export default class TranslationApiService extends ApiService<any> {
  constructor() {
    super('translation');
  }

  public translateFoodcard(venueId: string, sourceLang: string, targetLangs: string[]): AxiosPromise<any> {
    return axios.post(`${this.getBaseUrl()}/translate-foodcard/${venueId}`, { sourceLang, targetLangs });
  }
}
